import React, {useState, useEffect, useRef, useCallback} from 'react';
import _ from 'lodash';

import {Button, Checkbox, Input, Modal, notification} from 'antd';

import {
  CardHeader, PullRight,
} from '../../components/Layout/Layout';

export const GtinScannerInput = (props) => {
  console.log('PROPS', props);
  const {
    onAdd = (item, packingType) => {},
    lineItems = [], gtinKey = 'gtin',
    baseGtinKey = 'baseGtin',
    qtyCheckEnabled = false,
    qtyCheckItemKey = 'qtyFulfilled',
    barcodePrinterEnabled: barcodePrinterEnabledParam = false,
  } = props;
  const [gtinSearchString, setGtinSearchString] = useState('');
  // By default the printer is enabled, if activated by params
  const [barcodePrinterEnabled, setBarcodePrinterEnabled] = useState(barcodePrinterEnabledParam === true);

  const printBarcodeIframeRef = useRef();
  const handleBarcodePrint = useCallback((item) => {

    console.log('handleBarcodePrint item', item);

    const itemName = _.get(item, 'orderItemName', 'UNKNOWN').replace('[BUNDLE]', '').replace('MissPompadour', '').replace('CosyColours', '').replace('LittlePomp', '').trim();
    const baseGtin = _.get(item, baseGtinKey);
    const gtin = _.get(item, gtinKey) || baseGtin;
    if (!_.size(gtin) || !_.size(baseGtin)) {
      return;
    }

    // const openNewWindow = () => {
    //   const newWindow = window.open(fileURL);
    //   newWindow.print();
    // };

    const srcContent = `
      <script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.5/dist/JsBarcode.all.min.js"></script>
      <style>
        div { width: 150px; height: 70px; text-align: center; border: 1px solid #c1c1c1; }
        p { font-family: 'Arial'; font-size: 8px; color: black; margin-top: -3px; }
        img { width: 95%; height: 50px; margin-bottom: 0; border: none; }
      </style>
      <div>
        <img id="barcode"
          jsbarcode-format="ean13"
          jsbarcode-value="${gtin}"
          jsbarcode-displayValue="false"/>
        <p>${itemName} - <b>Mixed with <span style="font-size: 10px">♥</span></b></p>
      </div>
      <script>
      JsBarcode("#barcode").init();
      </script>
    `;

    Modal.success({
      title: 'Print Label',
      width: 300,
      closable: true,
      afterClose: () => {
        printBarcodeIframeRef.current = null;
      },
      content: (
          <div>
            <iframe src='about:blank' style={{ width: '100%', height: '200px'}} ref={printBarcodeIframeRef}/>
            {/*<Button onClick={openNewWindow}>Open in new Window</Button>*/}
          </div>
      ),
    });

    const print = () => {
      printBarcodeIframeRef.current.contentWindow.document.open();
      printBarcodeIframeRef.current.contentWindow.document.write(srcContent);
      printBarcodeIframeRef.current.contentWindow.document.close();
      setTimeout(() => {
        printBarcodeIframeRef.current.focus();
        printBarcodeIframeRef.current.contentWindow.print();
      }, 100);
    };

    setTimeout(() => {
      if (printBarcodeIframeRef.current) {
        print()
      } else {
        setTimeout(() => {
          // Try again after 1 Second
          if (printBarcodeIframeRef.current) {
            print();
          }
        }, 1000);
      }
    }, 500);
  }, [gtinKey]);

  useEffect(() => {
    if (!_.size(lineItems) || _.size(gtinSearchString) !== 13) {
      return;
    }
    const findItemByGtin = lineItems.find((item) => {
      // , _.get(item, 'qtyFulfilled', 0), _.get(item, 'packedQty', 0)

      // console.log('qtyCheckEnabled', qtyCheckEnabled, (_.get(item, 'qtyFulfilled') || _.get(item, 'packedQty')), Math.max(_.get(item, 'qtyFulfilled', 0), _.get(item, 'packedQty', 0)));
      const currentQty = _.get(item, `${qtyCheckItemKey}`, 0);
      const qtyUnfulfilled = (qtyCheckEnabled ? currentQty < _.get(item, 'qty') : true);
      const gtinMatch = _.get(item, gtinKey, '') === gtinSearchString && qtyUnfulfilled;
      const baseGtinMatch = _.get(item, baseGtinKey, '') === gtinSearchString && qtyUnfulfilled;
      // console.log('CHECK ITEM', item.orderItemName, currentQty, item.qty, {
      //   qtyUnfulfilled,
      //   gtinMatch,
      //   baseGtinMatch,
      // });
      return (gtinMatch || baseGtinMatch);
    });

    console.log('GTIN', gtinSearchString, lineItems, findItemByGtin);
    if (findItemByGtin) {
      setGtinSearchString('');
      onAdd(findItemByGtin, 'scan');

      if (barcodePrinterEnabled) {
        // Open Barcode Printer
        handleBarcodePrint(findItemByGtin);
      }
    } else {
      setGtinSearchString('');
      const audio = new Audio('/buzzer-error-compressed.mp3');
      audio.play();
      notification.error({
        message: `Cannot find item by GTIN ${gtinSearchString}`,
      });
    }
  }, [gtinSearchString, barcodePrinterEnabled]);

  const gtinScanningCodeRef = useRef('');
  // const isGtinScanningCodeReadingRef = useRef(false);
  useEffect(() => {
    const callback = (event) => {
      // Usually scanners throw an 'Enter' key at the end of read
      if (event.keyCode === 13) {
        if (gtinScanningCodeRef.current.length >= 13) {
          /// code ready to use
          setGtinSearchString(gtinScanningCodeRef.current);
          gtinScanningCodeRef.current = '';
        }
      } else {
        gtinScanningCodeRef.current += `${event.key}`;
      }
      //run a timeout of 200ms at the first read and clear everything
      // if (!isGtinScanningCodeReadingRef.current) {
      //   isGtinScanningCodeReadingRef.current = true;
      //   setTimeout(() => {
      //     gtinScanningCodeRef.current = '';
      //     isGtinScanningCodeReadingRef.current = false;
      //   }, 200);
      // }
    };
    document.addEventListener('keypress', callback);
    return () => document.removeEventListener('keypress', callback);
  }, []);

  useEffect(() => {
    const callback = (event) => {
      if(event.data.length >= 13){
        event.preventDefault();
        setGtinSearchString(event.data);
      }
    };
    document.addEventListener('textInput', callback);
    return () => document.removeEventListener('textInput', callback);
  }, []);

  return (
      <CardHeader>
        <Input type='number' value={gtinSearchString} onChange={({ target: { value }}) => setGtinSearchString(value)} placeholder='Scan or Search GTIN'/>
        {barcodePrinterEnabledParam ? (
            <PullRight>
              <Checkbox onChange={({ target: { checked }}) => setBarcodePrinterEnabled(checked)} checked={barcodePrinterEnabled}/>
              <label>{' '}Enable Barcode Printer</label>
            </PullRight>
        ) : null}
      </CardHeader>
  );
};
